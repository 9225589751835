<template>
  <MainLayout>
    <CreateFormLayout>
      <template v-slot:title__text>
        Сотрудник
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <div>
          <FormCardTitle title="Личные данные" />
          <FormInputBlock>
            <ValidationInputField
              label="Телефон"
              validate-name="телефон"
              rules="required"
              mask="+7 (9##) ###-##-##"
              v-model="phone_number"
            />
            <div />
            <ValidationInputField
              v-model="last_name"
              label="Фамилия"
              validate-name="фамилия"
            />
            <ValidationInputField
              v-model="first_name"
              label="Имя"
              validate-name="имя"
            />
            <ValidationInputField
              v-model="middle_name"
              label="Отчество"
              validate-name="отчество"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(createUser)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import { getAccessGroupsRequest } from "@/helpers/api/access-group";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "CreateStaffPage",
  data() {
    return {
      loading: false,
      groupSearch: async value => {
        return (
          await getAccessGroupsRequest({
            query: { search: value, organization: this.kindergartenId }
          })
        ).data.results.map(el => ({ text: el.title, value: el.id }));
      }
    };
  },
  props: {
    kindergartenId: {
      type: [String, Number]
    },
    serviceOrganizationId: {
      type: [String, Number]
    }
  },
  components: {
    ValidationInputField,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    MainLayout
  },
  computed: {
    phone_number: {
      get() {
        return this.$store.getters.getCreateUserForm.phone_number;
      },
      set(newValue) {
        this.$store.commit("setCreateUserForm", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    },
    group: {
      get() {
        return this.$store.getters.getCreateUserForm.group;
      },
      set(newValue) {
        this.$store.commit("setCreateUserForm", {
          fieldName: "group",
          value: newValue
        });
      }
    },
    first_name: {
      get() {
        return this.$store.getters.getCreateUserForm.first_name;
      },
      set(newValue) {
        this.$store.commit("setCreateUserForm", {
          fieldName: "first_name",
          value: newValue
        });
      }
    },
    last_name: {
      get() {
        return this.$store.getters.getCreateUserForm.last_name;
      },
      set(newValue) {
        this.$store.commit("setCreateUserForm", {
          fieldName: "last_name",
          value: newValue
        });
      }
    },
    middle_name: {
      get() {
        return this.$store.getters.getCreateUserForm.middle_name;
      },
      set(newValue) {
        this.$store.commit("setCreateUserForm", {
          fieldName: "middle_name",
          value: newValue
        });
      }
    }
  },
  mounted() {
    if (this.serviceOrganizationId)
      getAccessGroupsRequest({ id: this.serviceOrganizationId }).then(
        response => {
          this.group = response.data.results.id;
        }
      );
  },
  methods: {
    createUser() {
      this.loading = true;

      this.$store
        .dispatch("createUser")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.staff)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
